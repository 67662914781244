// Handles the payment process for PaidSubscriptions, through mpo Datatrans.
// Exposes:
//  - "sources", a collection of the user's pre-authorized auto_payment_sources.
//  - "launch(subscription)", which launches confirmation/creation popup.
//
// Note: Modal controller is declared at the bottom of this very file.

angular.module('app').service('SubscriptionPaymentService', ['$rootScope', '$q', '$http', '$timeout', '$uibModal', 'DatatransHelper', function ($rootScope, $q, $http, $timeout, $uibModal, DatatransHelper) {
  var scope = this;
  window.SubscriptionPaymentService = this;

  scope.apiEndpoint = '/api/frontend/paid_subscriptions';
  scope.sourcesLoaded = false;

  var modalInstances = {};

  function constructor() {
    $rootScope.$on('$destroy', destructor);
  }

  function destructor() {
    window.AutoPaymentService = null;
  }

  // Launches the entire process:
  // - If payment sources are available, choose existing or create new from selector popup.
  // - If no sources available or user decides to change, launch DataTrans popup and create new.
  // - Returns a promise that resolves into the updated subscription.
  function launch() {
    return $q((resolve, reject) => {
      let selectedAction = scope.subscription.payment_source ? launchConfirmationPopup : initiatePayment;
      selectedAction().then(result => resolve(result))
    })
  }

  // Launches the selector popup.
  // Proceeds to update the subscription and charge as needed (on the backend),
  // ... or to launch the payment popup if a new payment method is requested.
  // Returns a promise which resolves into the chosen/confirmed subscription:
  // Cancellation rejects the promise.
  function launchConfirmationPopup() {
    return $q((resolve, reject) => {
      let modalInstance = $uibModal.open({
        animation: $rootScope.animationsEnabled,
        templateUrl: `/ng/templates/paid_subscriptions/pay_confirmation_modal.html?locale=${I18n.locale}`,
        controller: 'SubscriptionPaymentConfirmationModal',
        windowClass: 'modal-rounded pay-confirmation-modal',
        resolve: {
          subscription: function() {
            return scope.subscription;
          }
        }
      });

      modalInstance.result.then((result) => {
        scope.initiatingPayment = false;

        if (result.action == 'confirm') {
          let params = {after_updated: 't'}
          updateSubscription(params).then(result => resolve(result))
        } else if (result.action == 'update_payment_method') {
          updateSubscription().then(result => {
            initiatePayment().then(result => resolve(result))
          })
        }
      }, (e) => { reject(e) }).finally(() => { scope.initiatingPayment = false });
    })
  }

  function updateSubscription(params) {
    params = params || {}
    params.subscription = scope.subscription

    return $q((resolve, reject) => {
      $http.patch(`${scope.apiEndpoint}/${scope.subscription.id}.json`, params).then(response => {
        scope.subscription = response.data;
        resolve(scope.subscription)
      })
    })
  }

  // - Popup for payment info input.
  // - Datatrans will handle.
  /** End of frontend responsibility **/
  // => next on the server: Datatrans will call "dt-state-hook" webhook with the response.
  function initiatePayment() {
    scope.payment = {
      identifier: scope.subscription.ref_number,
      subscription_id: scope.subscription.id,
      currency: scope.currency
    };

    if (scope.payNow) {
      scope.payment.amount = scope.subscription.amount;
    } else scope.payment.amount = 0;

    const paymentMethods = "VIS,ECA,AMX" // only credit cards currently supported

    return $q((resolve, reject) => {
      DatatransHelper.initiatePayment(scope.payment, {
        mpo: true, // this indicates that this is a recurring server-to-server payment process
        mode: 'lightbox',
        paymentmethod: paymentMethods,
        use_alias: 'yes', // force alias creation
        success_url: `${window.settingsSiteUrl}/paid_subscriptions/${scope.subscription.id}/success`,
        error_url: `${window.settingsSiteUrl}/paid_subscriptions/${scope.subscription.id}/success?status=failed`,
        cancel_url: `${window.settingsSiteUrl}/paid_subscriptions/${scope.subscription.id}/success?status=cancelled`,
        onClosed: onDatatransPopupClosed
      });

      $timeout(() => {
        scope.initiatingPayment = false;
        resolve(scope.subscription)
      }, 1000)
    })
  }

  // The above onclosed is only fired when the user manually closes the popup,
  // thus exiting before completing the transaction.
  function onDatatransPopupClosed() {
    if (Rails.env === "development") console.log("onDatatransPopupClosed");

    deleteSubscription();
    scope.initiatingPayment = false;
  }

  function deleteSubscription() {
    if (scope.subscription && scope.subscription.state !== "active") {
      $http.delete(`${scope.apiEndpoint}/${scope.subscription.id}.json`).then(response => {
        $rootScope.$broadcast("subscriptionDeleted");
        console.log("Subscription deleted successfully.");
      }, error => {
        console.error("Error deleting subscription:", error);
      });
    }
  }

  // Public.
  // This function should always return a subscription
  // no matter if we're using a newly created one, or if we're confirming an existing one,
  // or if we're choosing a different existing subscription/payment method (in the future).
  scope.launch = function(subscription) {
    if (scope.initiatingPayment == true) return;
    if (!subscription) return;

    scope.subscription = subscription;

    scope.initiatingPayment = true;

    return $q((resolve, reject) => {
      launch().then((result) => { resolve(result) }).finally(() => { scope.initiatingPayment = false })
    })
  }

  // Initialize.
  constructor()
}])
  .controller('SubscriptionPaymentConfirmationModal', ['$scope', '$uibModalInstance', 'subscription', function($scope, $uibModalInstance, subscription) {
    window.SubscriptionPaymentConfirmationModal = $scope;

    $scope.subscription = subscription;
    let result = { subscription: $scope.subscription, action: null };

    let paymentMethodCodes = {
        VIS: "credit_card",
        ECA: "credit_card",
        AMX: "credit_card",
        TWI: "twint",
        PAP: "paypal",
        PFC: "postfinance_card",
        PEF: "postfinance_efinance",
        PFE: "postfinance_efinance",
        MYO: "money_card"
    };

    $scope.paymentMethodCode = paymentMethodCodes[$scope.subscription.payment_source.pmethod];

    $scope.paymentMethodReference = $scope.subscription.payment_source.maskedCC

    $scope.onConfirmClicked = function () {
      result.action = 'confirm';
      $uibModalInstance.close(result)
    };

    $scope.onChangeClicked = function() {
      result.action = 'update_payment_method';
      $uibModalInstance.close(result)
    };

    $scope.onCancelClicked = function() {
      $uibModalInstance.dismiss('canceled by user')
    };
  }]);
