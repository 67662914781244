import {useEffect} from "react";
import {globalModalQueue} from "../../../shared-services/globalState/topLayerState";
import {useSelector} from "../../../shared-services/redux-tiny";

const useModalQueue = (explicitModalName) => {
  const {modalQueue, currentModal} = useSelector(state => state.topLayer);
  const {closeModal, pushToQueue, moveQueue} = globalModalQueue(explicitModalName);

  useEffect(() => {
    const timeout = 500;
    if (modalQueue.length && !currentModal) {
      setTimeout(moveQueue, timeout);
    };
  }, [modalQueue]);

  return {
    currentModal,
    pushToQueue,
    closeModal
  };
};

export default useModalQueue;
