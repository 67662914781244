import globalState from "../globalState";
import * as _ from "underscore";

const topLayerGlobalState = () => {
  const {getGlobalState, setGlobalState} = globalState();
  const stateName = "topLayer";

  const globalTopLayer = getGlobalState(stateName);
  let topLayerState = getGlobalState(stateName) || {
    modalQueue: [],
    currentModal: null,
    cookieBannerOpen: false,
    ...globalTopLayer
  };

  const setTopLayerState = (newState) => {
    const currentState = getGlobalState(stateName);
    const candidateNewState = {...currentState, ...newState};

    if (!_.isEqual(candidateNewState, currentState)) {
      topLayerState = {...candidateNewState};
      setGlobalState(stateName, topLayerState);
    }
  };

  const fullLoaderVisible = (show) => {
    setTopLayerState({loaderOpen: show});
  };

  const setCurrentModal = (currentModal) => {
    const onClose = topLayerState?.currentModal?.onClose;
    setTopLayerState({currentModal});

    if (onClose) onClose();
    if (currentModal?.onOpen) currentModal.onOpen();
  };

  const setModalQueue = (queue) => {
    setTopLayerState({modalQueue: queue});
  };

  return {...topLayerState, setTopLayerState, fullLoaderVisible, setCurrentModal, setModalQueue};
};
export default topLayerGlobalState;

export const globalModalQueue = (explicitModalName = null) => {
  const pushToQueue = (modalName = explicitModalName, options = {}) => {
    const {setModalQueue} = topLayerGlobalState();
    let {modalQueue, currentModal} = topLayerGlobalState();
    const minNameLength = 2;

    if (modalName === currentModal?.name) return;
    if (modalQueue.map(modal => modal?.name)?.includes(modalName)) return;
    if (!modalName || modalName.length < minNameLength) return;

    const newModal = {name: modalName, ...options};

    modalQueue = [...modalQueue, newModal];
    setModalQueue(modalQueue);
  };

  const closeModal = (modalName = explicitModalName) => {
    const {setCurrentModal} = topLayerGlobalState();
    const {modalQueue, currentModal} = topLayerGlobalState();

    if (modalName !== currentModal?.name) return;

    if (modalQueue.length) moveQueue();
    else {
      setCurrentModal(null);
    }

    if (currentModal?.onClose) currentModal.onClose();
  };

  const moveQueue = () => {
    const {modalQueue, setModalQueue, setCurrentModal, cookieBannerOpen} = topLayerGlobalState();
    if (!modalQueue || modalQueue.length === 0) return;

    const move = () => {
      const currentModal = modalQueue.shift();
      if (!currentModal) return;

      setCurrentModal(currentModal);
      setModalQueue(modalQueue);
    };

    if (cookieBannerOpen) {
      const moveIfCookieBannerClosed = (event) => {
        const {name} = event?.detail;
        if (name !== "topLayer") return;

        const {cookieBannerOpen} = event?.detail?.state;
        if (cookieBannerOpen) return;

        const delay = 4000;
        const timeout = setTimeout(() => {
          move();
          clearTimeout(timeout);
        }, delay);

        removeEventListener("globalStateUpdated", moveIfCookieBannerClosed);
      };

      addEventListener("globalStateUpdated", moveIfCookieBannerClosed);
    } else move();
  };

  return {pushToQueue, closeModal, moveQueue};
};
