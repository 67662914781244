angular.module('app').service('StringToProductParser', [function () {

  var numberPlusUnits = RegExp(/\d+((\.|\,|\/)\d*)?\s*\S+/g);
  var justNumbers = RegExp(/\d+((\.|\,|\/)\d*)?/g);
  var removeFromName = RegExp(/\,|\.|\//g);
  var removeFromQuantity = RegExp(/^\,|^\./);

  this.parseString = function(string) {
    productName = extractProductName(string);
    quantities = getQuantities(string);

    return {
      productName: productName,
      quantities: quantities
    };
  };

  this.parseMultiple = function(string) {
    return string.split(/[\/\r?\n]/)
  };

  var extractQuantityLabels = function(string) {
    var labels = string.match(numberPlusUnits);
    return labels ? _(labels).map(function(label){ return label.toLowerCase().replace(removeFromQuantity, '') }) : string.match(justNumbers) || []
  };

  var splitQuantityLabels = function(quantity_labels) {
    return _(quantity_labels).map(function(label){
      return splitQuantityLabel(label)
    })
  };

  var splitQuantityLabel = function(label) {
    var quantity = parseFloat(label.match(justNumbers)[0].replace(',', '.'));
    var units = label.replace(justNumbers, '').replace('  ', ' ').trim().replace(removeFromName, '')

    return {
      quantity: quantity,
      units: units
    }
  };

  var getQuantities = function(string) {
    return splitQuantityLabels(extractQuantityLabels(string));
  };

  var extractProductName = function(string) {
    let productName = string.toLowerCase();
    const quantity_labels = extractQuantityLabels(string) || [];

    if(quantity_labels.length > 0) {
      _(quantity_labels).each(function(label) {
        productName = productName.replace(label, '');
      });
    }
    productName = productName.replace(removeFromName, '').replace('  ', ' ').trim();
    productName = productName.split(" ").filter(string => string.length > 1).join(" ");
    return productName;
  };
}]);
