/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

function importAll(r) {
  r.keys().forEach(r);
}

// import * as _ from 'underscore';
// import * as $ from 'jquery';
// import * as sprintf from 'sprintf';
require('../ng-frontend/vendor/moment-with-locales.min');

require("babel-core/register");
// require("babel-polyfill");

import * as lzstring from 'lz-string';
window.lzstring = lzstring;

import 'angular15farmy';
// import 'angular-resource15';
import 'angular-touch15';
import 'angular-animate15';
import 'angular-sanitize15';
import 'angular-cookies15';
import 'angular-aria15';
import 'angular-translate';
import 'angular-translate-loader-url';
import 'angular-block-ui';

import 'ng-combo-date-picker';

require('../ng-frontend/angular-integration');
importAll(require.context('../ng-frontend/modules', true, /\.js$/));
importAll(require.context('../ng-frontend/utils', true, /\.js$/));

require('../ng-frontend/ng-frontend-init');
importAll(require.context('../ng-frontend/config', true, /\.js$/));
importAll(require.context('../ng-frontend/services', true, /\.js$/));
importAll(require.context('../ng-frontend/controllers', true, /\.js$/));
importAll(require.context('../ng-frontend/directives', true, /\.js$/));
importAll(require.context('../ng-frontend/filters', true, /\.js$/));
importAll(require.context('../ng-frontend/resources', true, /\.js$/));
importAll(require.context('../ng-frontend/locale', true, /\.js$/));

