import PusherService from "./pusher-service";
import * as gdl from "./google-datalayer-helper";
import * as adl from "./algolia-datalayer-helper";
import {hashUserDetails} from "./hashing-module";

// Pusher
// TODO (Mike): move and isolate this logic.
const pusherChannels = {};
const pusherActions = {
  order_complete: {
    action: (data) => {},
    options: {allowAnonymous: false}
  }
};
const defaultPusherActions = ["order_complete"];

const pusherSubscribe = (pusherEvents = [], user) => {
  const currentUser = user || window.UserService?.currentUser;
  const {bind, getUserChannelName} = PusherService();
  const channelName = getUserChannelName(currentUser);

  pusherEvents.forEach(eventName => {
    if (!pusherChannels[eventName] && pusherActions[eventName]) {
      const {action, options} = pusherActions[eventName];
      if (!options.allowAnonymous && !currentUser?.id) return;

      pusherChannels[eventName] = bind(channelName, eventName, action);
    }
  });
};

const pusherUnsubscribe = (pusherEvents = []) => {
  const UserService = window.UserService;
  if (!UserService) return;

  const {unbind, getUserChannelName} = PusherService();
  const channelName = getUserChannelName(UserService.currentUser);

  pusherEvents.forEach(eventName => {
    unbind(channelName, eventName);
  });
};

const getShippingTier = deliveryTime => {
  if (!deliveryTime) return null;
  const currentDate = new Date();
  const dateDifference = (Date.parse(deliveryTime) - currentDate) / (1000 * 60 * 60 * 24);

  if (dateDifference < 8) return "week1";
  if (dateDifference < 16) return "week2";
  if (dateDifference < 22) return "week3";
  if (dateDifference >= 22) return "week4+";
};

//  Returns the caller location for the AddToCart event for ContentSquare based on the URL
const getCallerLocation = () => {
  let callerLocation = "unknown";
  if (location.pathname === "/") callerLocation = "homepage";
  if (location.pathname.includes("/ct/")) callerLocation = "landingpage";
  if (location.pathname.includes("/cart")) callerLocation = "cart";
  if (window.CatalogServiceHelper.isCatalogPage()) callerLocation = "catalog";
  if (location.pathname.includes("/products") && location.search.includes("keywords")) callerLocation = "search-results";
  return callerLocation;
};

// Page views
const trackPageView = async(eventName, options) => {
  try {
    if (options?.userDetails) {
      const nonHashedEmail = options.userDetails.email;
      options.userDetails = await hashUserDetails(options.userDetails);
      if (eventName === "order") {
        options.userDetails.no_hashed_email = nonHashedEmail; // Needed for TrustedShops badge
      }
    }
    if (options?.type === "algolia") {
      adl.trackEvent(eventName, options);
    } else {
      gdl.gdlTrackEvent(eventName, options);
    }

    return `Page View ${eventName} tracked`;
  } catch (e) {
    console.error(`Failed to track event ${eventName}: ${e}`);
    throw new Error(`Failed to track event ${eventName}: ${e}`);
  }
};

// Events
const trackEvent = async(eventName, options = null) => {
  try {
    if (options && options?.userDetails) {
      for (const key in options.userDetails) {
        if (!options.userDetails[key]) {
          console.error(`Missing value for key: ${key}`);
        }
      }
      options.userDetails = await hashUserDetails(options.userDetails);
    }
    if (eventName === "add_shipping_time" && options?.checkout) options.checkout.shipping_tier = getShippingTier(options.checkout?.delivery_time);

    if (options?.type === "algolia") {
      adl.trackEvent(eventName, options);
    } else {
      gdl.gdlTrackEvent(eventName, options);
    }

    if (window.Rails.env !== "production") {
      console.log(`Event processed: ${eventName}`, options);
    }

    return `Event ${eventName} tracked`;
  } catch (e) {
    console.error(`Failed to track event ${eventName}: ${e}`);
    throw new Error(`Failed to track event ${eventName}: ${e}`);
  }
};

window.TrackingHelper = {
  trackEvent,
  trackPageView
};
export {trackEvent, trackPageView, pusherSubscribe, pusherUnsubscribe, hashUserDetails, getCallerLocation};
