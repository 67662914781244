/** Redux-tiny
 * @author Mike
 *
 * Mimics Redux functionality through React's useReducer and Context api,
 * while integrating with AngularJS main scope services.
 * Allows for better hybrid integration and eases compatibility with upcoming Next/React environments.
 *
 * @exports useSelector => works as expected from redux useSelector.
 * @exports ReduxTinyProvider => main Context provider, use on top-most target components.
 */

import React, {
  useReducer,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  useMemo
} from "react";

import globalState from "./globalState";

import cartParser, {cartComparator} from "../react/cart/cartParser";

import AngularIntegration from "../angular-integration";

const {$on, $broadcast} = AngularIntegration;
const userService = window.UserService;
const {didCartChange} = cartComparator();

const {setGlobalState, getGlobalState} = globalState();

const getContext = () => {
  const getCartContext = () => {
    const globalCartState = getGlobalState("cart");
    const cartInitialState = {
      cartLoadRequested: false,
      setCurrentCart: () => {},
      ...globalCartState
    };

    const initialState = getGlobalState("cart") || cartInitialState;
    return createContext(initialState);
  };

  const getSessionContext = () => {
    const globalSession = getGlobalState("session");
    const sessionInitialState = {
      currentUser: userService?.currentUser,
      sessionContext: null,
      masterFilters: null,
      topTaxonFilters: null,
      setCurrentUser: () => {},
      setTopTaxonFilters: () => {},
      setMasterFilters: () => {},
      setSessionContext: () => {},
      updateNotifications: () => {},
      notifications: [],
      currentHub: window.currentHubName,
      currentZipcode: window.currentZipcode,
      marketingZone: window.currentMarketingZone,
      ...globalSession
    };

    return createContext(sessionInitialState);
  };

  const getTopLayerContext = () => {
    const topLayerInitialState = getGlobalState("topLayer") || {
      loaderOpen: false,
      modalQueue: [],
      currentModal: null,
      setTopLayerState: () => {},
      fullLoaderVisible: () => {}
    };

    return createContext(topLayerInitialState);
  };

  return {getCartContext, getSessionContext, getTopLayerContext};
};

const {getCartContext, getSessionContext, getTopLayerContext} = getContext();

// Added new action types for clarity in dispatching and handling actions
const ActionTypes = {
  UPDATE: "update"
};

const sharedReducer = (state, action) => {
  // Enhanced reducer to handle different action types
  switch (action.type) {
  case ActionTypes.UPDATE:
    return {...state, ...action.state};
  default:
    return state;
  }
};

const CartContext = getCartContext();
const CartProvider = ({children}) => {
  const [newState, setNewState] = useState(null);
  const [broadcast, setBroadcast] = useState(true);
  const [state, dispatch] = useReducer(sharedReducer, useContext(CartContext));
  const cartRef = useRef(false);

  const parseCart = cartParser();

  const setCartState = (newState) => {
    setNewState(prevState => ({...prevState, ...newState}));
  };

  const setCurrentCart = (cart, options = {isLocalUpdate: true}) => {
    const {isLocalUpdate} = options;

    if (didCartChange(state?.currentCart, cart)) {
      setNewState(prevState => {
        return {...prevState, currentCart: parseCart(cart, {isLocalUpdate})};
      });
    }
  };

  const requestCartLoad = () => {
    setNewState(prevState => ({...prevState, cartLoadRequested: true}));
  };

  const signalCartWasLoaded = () => {
    setNewState(prevState => ({...prevState, cartLoadRequested: false}));
  };

  const disableBroadcast = () => {
    setBroadcast(false);
  };

  const enableBroadcast = () => {
    setBroadcast(true);
  };

  useEffect(() => {
    if (cartRef.current) {
      dispatch({type: "update", state: newState});
      setGlobalState("cart", newState);
      if (broadcast) $broadcast("cart:updated", newState?.currentCart);
      else enableBroadcast();
    }
  }, [newState]);

  useEffect(() => {
    cartRef.current = true;
    const eventHandlers = {};

    if (!state?.currentCart?.id) setNewState(getGlobalState("cart"));

    $on("cart:changed", (event, response) => {
      const cart = response?.cart?.cart || response?.cart || response;
      if (cartRef.current && cart?.id) {
        disableBroadcast();
        setCurrentCart(cart || {});
      }
    }).then(handler => {
      eventHandlers["cart:changed"] = handler;
    });
    return () => {
      cartRef.current = false;
      Object.keys(eventHandlers).forEach(key => eventHandlers[key]());
    };
  }, []);

  return <CartContext.Provider value={{...state, setCurrentCart, setCartState, requestCartLoad, signalCartWasLoaded}}>
    {children}
  </CartContext.Provider>;
};

const SessionContext = getSessionContext();
const SessionProvider = ({children}) => {
  const [state, dispatch] = useReducer(sharedReducer, useContext(SessionContext));

  const [newState, setNewState] = useState(null);
  const sessionRef = useRef(false);
  const PromotionsService = window?.PromotionsService;
  const SmartPassService = window?.SmartPassService;

  const setSessionState = (newState) => {
    setNewState(prevState => ({...prevState, ...newState}));
  };

  const setCurrentHub = (currentHub) => {
    setSessionState({currentHub});
  };

  const setCurrentZipcode = (currentZipcode, broadcast = true) => {
    if (!currentZipcode) return;

    setSessionState({currentZipcode});
    if (broadcast) $broadcast("react:zipcode:changed", {zipcode: currentZipcode});
  };

  const setMarketingZone = (marketingZone) => {
    setSessionState({marketingZone});
  };

  const setCurrentSearch = (currentSearch) => {
    setSessionState({currentSearch});
  };

  // Added a setter function for updating currentUser
  const setCurrentUser = (currentUser) => {
    setSessionState({currentUser});
  };

  const setTopTaxonFilters = (topTaxonFilters) => {
    setSessionState({topTaxonFilters});
  };

  const setMasterFilters = (masterFilters) => {
    setSessionState({masterFilters});
  };

  const setSessionContext = (sessionContext) => {
    setSessionState({sessionContext});
  };

  const updateNotifications = (notifications) => {
    setSessionState({notifications});
  };

  const completeCurrentUser = (user) => {
    if (Object.keys(user).length === 0) return {};
    // TODO Mike: remove the lines below when the fix ticket is done.
    // HACK alert: while we solve this ticket => https://farmyag.atlassian.net/browse/IM-8515
    // farmy_family info is inside "additional_attributes".
    const farmy_family = user?.farmy_family || user?.additional_attributes?.farmy_family || null;
    // !todo

    const canSeeFarmyFamily = true;

    // SmartPass
    const smartPass = SmartPassService?.hasActiveSubscription
      ? {
        type: window.currentSmartPassType,
        level: window.currentSmartPassLevel,
        until: window.currentSmartPassUntilDate
      }
      : null;

    const hasShippingSubscription = SmartPassService?.hasShippingSubscription;
    const hasActiveSubscription = SmartPassService?.hasActiveSubscription;

    return {...user, farmy_family, smartPass, hasShippingSubscription, hasActiveSubscription, canSeeFarmyFamily: canSeeFarmyFamily};
  };

  const getCurrentSessionState = () => {
    return getGlobalState("session");
  };

  useEffect(() => {
    if (sessionRef.current) {
      dispatch({type: ActionTypes.UPDATE, state: newState});
      setGlobalState("session", newState);
    }
  }, [newState]);

  useEffect(() => {
    sessionRef.current = true;
    const eventHandlers = {};

    $on("zipcode:changed", (event, response) => {
      if (sessionRef.current) {
        if (response?.zipcode) setCurrentZipcode(response?.zipcode, false);
        if (response?.marketingZone) setMarketingZone(response?.marketingZone, false);
      }
    }).then(handler => {
      eventHandlers["zipcode:changed"] = handler;
    });

    $on("user:updated", (event, response) => {
      if (sessionRef.current) {
        setCurrentUser(completeCurrentUser(response?.user) || {});
      }
    }).then(handler => {
      eventHandlers["user:updated"] = handler;
    });

    $on("search:update", (event, response) => {
      if (sessionRef.current) {
        setCurrentSearch(response);
      }
    }).then(handler => {
      eventHandlers["search:update"] = handler;
    });

    $on("promo:updated", (event, response) => {
      if (sessionRef.current) {
        setSessionState({currentPromotion: {...response.promotion} || {}});
      }
    }).then(handler => {
      eventHandlers["promo:updated"] = handler;
    });

    if (PromotionsService?.currentPromotion) {
      setSessionState({currentPromotion: PromotionsService?.currentPromotion || {}});
    }

    return () => {
      sessionRef.current = false;
      Object.keys(eventHandlers).forEach(key => eventHandlers[key]());
    };
  }, []);

  return <SessionContext.Provider
    value={
      {
        ...state,
        setCurrentUser,
        setCurrentSearch,
        setTopTaxonFilters,
        setMasterFilters,
        setSessionContext,
        setSessionState,
        updateNotifications,
        setCurrentHub,
        setCurrentZipcode,
        setMarketingZone
      }}>
    {children}
  </SessionContext.Provider>;
};

const TopLayerContext = getTopLayerContext();
const TopLayerProvider = ({children}) => {
  const [state, dispatch] = useReducer(sharedReducer, useContext(TopLayerContext));
  const [newState, setNewState] = useState(null);
  const topLayerRef = useRef(false);

  const setTopLayerState = (newState) => {
    setNewState(prevState => ({...prevState, ...newState}));
  };

  const fullLoaderVisible = (show) => {
    setTopLayerState({loaderOpen: show});
  };

  const setCurrentModal = (currentModal) => {
    setTopLayerState({currentModal});
  };

  const setModalQueue = (queue) => {
    setTopLayerState({modalQueue: queue});
  };

  useEffect(() => {
    topLayerRef.current = true;

    if (topLayerRef.current) {
      dispatch({type: ActionTypes.UPDATE, state: newState});
      setGlobalState("topLayer", newState);
    }

    return () => {
      topLayerRef.current = false;
    };
  }, [newState]);

  return <TopLayerContext.Provider value={{...state, fullLoaderVisible, setTopLayerState, setCurrentModal, setModalQueue}}>
    {children}
  </TopLayerContext.Provider>;
};

export const ReduxTinyProvider = ({children}) => {
  return (
    <TopLayerProvider>
      <CartProvider>
        <SessionProvider>
          {children}
        </SessionProvider>
      </CartProvider>
    </TopLayerProvider>
  );
};

// Updated useSelector with memoization
export const useSelector = (selector) => {
  const selectorRef = useRef(false);
  const contexts = {
    cart: getCartContext(),
    session: getSessionContext(),
    topLayer: getTopLayerContext()
  };

  const context = {};

  const key = selector.toString().match(/\.(\w*)/)?.[1];
  context[key] = useContext(contexts[key]);
  const [contextState, setContextState] = useState({...context});

  useEffect(() => {
    selectorRef.current = true;
    const onGlobalStateUpdated = (event) => {
      if (selectorRef.current && event?.detail?.name === key && event?.detail?.state) {
        const newContext = {};
        newContext[key] = {...contextState[key], ...event.detail.state};
        setContextState(newContext);
      }
    };

    addEventListener("globalStateUpdated", onGlobalStateUpdated);

    return () => {
      selectorRef.current = false;
      removeEventListener("globalStateUpdater", onGlobalStateUpdated);
    };
  }, []);

  // We use useMemo to remember the result of the selector function.
  // useMemo will only be recalculated when the cart or session state changes.
  return useMemo(() => {
    return selector({...contextState});
  }, [contextState]);
};

export const useSessionActions = () => {
  const actions = useContext(SessionContext);
  const {
    setTopTaxonFilters,
    setCurrentUser,
    setMasterFilters,
    setSessionContext,
    updateNotifications,
    setCurrentHub,
    setCurrentZipcode,
    setMarketingZone
  } = actions;

  return {
    setTopTaxonFilters,
    setCurrentUser,
    setMasterFilters,
    setSessionContext,
    updateNotifications,
    setCurrentHub,
    setCurrentZipcode,
    setMarketingZone
  };
};

export const useCartActions = () => {
  const actions = useContext(CartContext);
  const {
    setCurrentCart,
    setCartState,
    requestCartLoad,
    signalCartWasLoaded
  } = actions;

  return {
    setCurrentCart,
    setCartState,
    requestCartLoad,
    signalCartWasLoaded
  };
};

export const useTopLayerActions = () => {
  const actions = useContext(TopLayerContext);
  const {fullLoaderVisible, setTopLayerState, setCurrentModal, setModalQueue} = actions;

  return {fullLoaderVisible, setTopLayerState, setCurrentModal, setModalQueue};
};
