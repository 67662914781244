export const trackEvent = (event, options) => {
  const params = parseSearchInsightOptions(event, options);
  if (window.dataLayer == null) window.dataLayer = [];
  window.dataLayer.push({event, ...params});
};

export const parseSearchInsightOptions = (event, options) => {
  if (!options || !event) return null;
  let insightsObject = {};
  if (event === "setAlgoliaAuthenticatedUserToken") {
    insightsObject = {
      algoliaAuthenticatedUserToken: `${options?.authenticatedUserToken}`
    };
  } else {
    insightsObject = {
      algoliaUserToken: window.ahoy.getVisitId(),
      index: options.product?.index_name,
      queryID: options.product?.query_id,
      objectIDs: [`${options.product?.id}`]
    };
  }
  if (options?.positionInList || options?.positionInList === 0) insightsObject.positions = [options.positionInList + 1];

  return insightsObject;
};
