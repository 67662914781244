import {useState, useEffect} from 'react';
import ThemeFarmy from '../react-themes/Theme.farmy';
import {useLocation} from 'react-router-dom';

const useWindowTools = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [scrollPosition, setScrollPosition] = useState(window.scrollY);
  const location = useLocation();

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleScroll);
    };
  }, [window.innerWidth, window.scrollY]);

  useEffect(() => {
    const saveScrollPosition = () => {
      sessionStorage.setItem(`${location.pathname}-scrollPosition`, window.scrollY.toString());
    };

    window.addEventListener('beforeunload', saveScrollPosition);

    return () => {
      window.removeEventListener('beforeunload', saveScrollPosition);
    };
  }, [location]);

  useEffect(() => {
    const savedPosition = sessionStorage.getItem(`${location.pathname}-scrollPosition`);
    if (savedPosition) {
      window.scrollTo(0, parseInt(savedPosition, 10));
      sessionStorage.removeItem(`${location.pathname}-scrollPosition`);
    }
  }, [location]);

  const isMobile = windowWidth < ThemeFarmy.breakPoints.sm;

  return {windowWidth, isMobile, scrollPosition};
};

export default useWindowTools;
