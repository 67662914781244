import * as _ from "underscore";
import {trackEvent} from "./tracking-helper";

/**
 * Service for reading, changing and authneticating the 'current user'
 */
angular.module("app").service("UserService", ["$rootScope", "$q", "$http", "$interval", "$timeout", "$translate", "Alerts", "$cookies", "CartData", "Hubs", "$localStorage", "$uibModal", "UserNotifications", "$location", ($rootScope, $q, $http, $interval, $timeout, $translate, Alerts, $cookies, CartData, Hubs, $localStorage, $uibModal, UserNotifications, $location) => {
  const $scope = this || {};

  window.UserService = $scope;

  $scope.currentUser = {};
  $scope.currentRoles = window.currentUserRoles || [];
  $scope.currentWeeklyId = window.currentWeeklyId;
  $scope.mode = window.currentWeeklyId == null ? "normal" : "weekly";
  $scope.currentUserId = window.currentUserId || null;
  $scope.isCorporateBuyer = false;
  $scope.isLoggedIn = $scope.currentUserId != null;
  $scope.isAdmin = false;
  $scope.isSupplier = false;
  $scope.activeSmartpass = null; // TODO
  $scope.hasUnderliveredOrders = false;
  $scope.phoneVerified = null;
  $scope.isLoading = false;
  $scope.canSeeFarmyFamily = false;

  $scope.loginOptions = {backToUrl: "", forcePageReload: false};

  if (Rails.env == "development") { window.$cookies = $cookies }

  try {
    $scope.preferredCartOrderSetAt = $localStorage.userSessionpreferredCartOrderSetAt ? moment($localStorage.userSessionpreferredCartOrderSetAt).toDate() : moment().add(-360, "days").toDate();
  } catch (e) {
    console.error(e);
    $scope.preferredCartOrderSetAt = moment().addDays(-360, "days").toDate();
  }

  /**
   * Set by cart data when multiple cart orders are detected
   * @type {boolean}
   */
  $scope.hasOtherCartOrders = false;

  /**
   * Placeholder for UserAccount data (balance in cash, points)
   *
   * @type {object}
   */
  $scope.account = {};

  $scope.updateCurrentUser = (userParams) => {
    const endpoint = "/api/frontend/users/";

    $scope.isLoading = true;

    return new Promise((resolve, reject) => {
      $http
        .put(endpoint + $scope.currentUserId + ".json", {...userParams})
        .then(response => {
          $scope.currentUser = angular.extend($scope.currentUser || {}, response.data);
          $rootScope.$broadcast("user:updated", {user: $scope.currentUser});
          resolve(response);
        }, e => {
          reject(e);
          console.error({e});
        })
        .finally(function() {
          $scope.isLoading = false;
        });
    });
  };

  $scope.authenticate = async function(email, password, verifyToken) {
    try {
      const response = await $http.post("/login.json", {
        spree_user: {
          email,
          password,
          verify_token: verifyToken
        },
        noha: "t"
      }, {Authorization: null});

      if (response.data.pending_verification) {
        return response.data;
      }

      if (response.data.user?.delete_after) {
        $scope.showDeletedAccountModal();
        $scope.logout();
        throw new Error("Account marked for deletion");
      }

      $scope.currentUser = angular.extend($scope.currentUser || {}, response.data.user);
      window.currentZipcode = window.currentZipcode || $scope.currentUser.last_used_zipcode;
      window.currentUserId = response.data.user.id;
      window.currentUserHubId = response.data.user.hub_id;
      $scope.currentRoles.length = 0;
      _.each(response.data.roles, r => $scope.currentRoles.push(r));
      window.currentUserRoles = $scope.currentRoles;

      $scope.phoneVerified = $scope.currentUser.phone_verified_at != null;
      if (response.data.authenticity_token) {
        $("meta[name=csrf-token]").attr("content", response.data.authenticity_token);
      }

      checkRoles();
      checkFlags();
      canUserSeeFarmyFamily();
      UserNotifications.loadNotifications();
      $scope.verifyPhoneIfNeeded();

      trackEvent("setAlgoliaAuthenticatedUserToken", {authenticatedUserToken: $scope.currentUser?.id, type: "algolia"});

      await $http.get("/users/my_cookies.html?noha=t");
      $scope.isLoggedIn = true;
      trackEvent("login", {
        gtmObject: {
          status: "successful"
        },
        userDetails: {
          email: email
        }
      });

      $rootScope.$broadcast("user:authenticated", {user: $scope.currentUser, hub_id: window.currentUserHubId});
      $rootScope.$broadcast("user:updated", {user: $scope.currentUser});
      $rootScope.$broadcast("zipcode:changed", {zipcode: window.currentZipcode});
      return response.data;
    } catch (error) {
      $scope.currentUserId = null;
      $scope.isLoggedIn = false;
      Alerts.error(error.data.error);
      trackEvent("login", {gtmObject: {status: "error", code: error.data.error}});
      throw error;
    }
  };

  $scope.authenticateByToken = function(token) {
    return $q((resolve, reject) => {
      $http.post("/users/authenticate_by_token.json", {noha: "t"}, {
        headers: {
          Authorization: "Bearer " + token
        }
      }).then(function(response) {
        $scope.currentUser = angular.extend($scope.currentUser || {}, response.data.user);
        window.currentZipcode = window.currentZipcode || $scope.currentUser.last_used_zipcode;
        window.currentUserId = $scope.currentUserId = response.data.user.id;
        window.currentUserHubId = response.data.user.hub_id;
        $scope.currentRoles.length = 0; // keep roles immutable
        _.each(response.data.roles, r => $scope.currentRoles.push(r));
        window.currentUserRoles = $scope.currentRoles;

        // Refresh authenticity token
        if (response.data.authenticity_token) { $("meta[name=csrf-token]").attr("content", response.data.authenticity_token) }

        checkRoles();
        checkFlags();
        canUserSeeFarmyFamily();
        UserNotifications.loadNotifications();

        trackEvent("setAlgoliaAuthenticatedUserToken", {authenticatedUserToken: $scope.currentUser?.id, type: "algolia"});

        $http.get("/users/my_cookies.html").then(r => {
          $scope.isLoggedIn = true;
          $rootScope.$broadcast("user:authenticated", {user: $scope.currentUser, hub_id: window.currentUserHubId});
          $rootScope.$broadcast("user:updated", {user: $scope.currentUser});
          $rootScope.$broadcast("zipcode:changed");
          resolve(response.data);
        });
      }, e => reject(e));
    });
  };

  $scope.signupUser = function(userData) {
    return $q((resolve, reject) => {
      userData.locale = $translate.use();
      $http.post("/api/signup.json", {spree_user: userData, noha: "t"}).then(function(response) {
        Alerts.success($translate.instant("user_registrations.signed_up"));
        $scope.jwtToken = response.data.token;
        $scope.deviseToken = response.data.devise_token;

        if (response.data.authenticity_token) { $("meta[name=csrf-token]").attr("content", response.data.authenticity_token) }

        $rootScope.$broadcast("user:registered", {userData: response.data});
        $rootScope.$broadcast("user:updated", {user: $scope.currentUser});

        $scope.authenticate(userData.email, userData.password).then(userData => {
          resolve(userData);
        }, e => reject(e));
      }, function(error) {
        Alerts.error($translate.instant("cannot_perform_operation"));
        reject(error);
      });
    });
  };

  $scope.logout = function() {
    $scope.loggingOut = true;

    const previousUser = UserService.currentUser;

    return $http.get("/user/spree_user/logout.json?noha=t").then(response => {
      trackEvent("setAlgoliaAuthenticatedUserToken", {authenticatedUserToken: undefined});
      if (shouldRedirectHome()) {
        $location.search("show_jumbo", "t").url("/");
      }

      // Clear localstorage
      window.localStorage.clear();
      // Delete cookies locally too
      for (const k in $cookies.getAll()) {
        if (k != "CSRF-TOKEN") // keep the authenticity token for next requests
        { $cookies.remove(k) }
      }

      // TODO: Tell serviceworker to clear cache

      $scope.currentUser = {};
      $scope.currentUserId = null;
      $scope.isLoggedIn = false;
      $scope.currentRoles.length = 0;
      $scope.isAdmin = false;
      $scope.isCorporateBuyer = false;
      canUserSeeFarmyFamily();
      // Update authenticity token, if a new one has been received
      if (response.data.authenticity_token) {
        // $('meta[name=csrf-token]').attr('content', response.data.authenticity_token);
        // $cookies.put('CSRF-TOKEN', response.data.authenticity_token);
      }

      // At this point, there's a wrong CSRF-TOKEN stuck in the client-side session,
      // so we need make a GET request to get the new token
      $http.get("/users/my_cookies.html?noha=t").then(r => {
        // Set default hub
        $rootScope.$broadcast("user:logout", {user: $scope.currentUser, hub_id: window.currentUserHubId, previousUser});
        $rootScope.$broadcast("user:updated", {user: $scope.currentUser});

        $scope.loggingOut = false;

        // TODO: Maybe this belongs to to the Hubs service instead, and must just react to the event
        // The delay is added to ensure authenticity cookies are refreshed
        $timeout(() => {
          Hubs.setCurrentHubFromZipcode((window.currentZipcode && window.currentZipcode.length == 4 && window.currentZipcode) || "8005");
        }, 10);
      }).finally(() => {
        $scope.loggingOut = false;
      });
    });
  };

  $scope.loadUser = function() {
    return $q((resolve, reject) => {
      const params = {
        nocards: "t",
        noha: "t"
      };

      $http.get("/api/users/current.json", {params}).then(response => {
        $scope.currentUser = response.data;
        window.currentZipcode = window.currentZipcode || $scope.currentUser.last_used_zipcode;
        window.currentUserId = $scope.currentUserId = $scope.currentUser.id;
        window.currentUserHubId = $scope.currentUser.hub_id;

        $scope.phoneVerified = $scope.currentUser.phone_verified_at != null;

        if (response.data.zipcode) window.currentZipcode = response.data.zipcode;

        $scope.currentRoles.length = 0; // keep roles immutable
        _.each(response.data.roles, r => $scope.currentRoles.push(r));
        checkRoles();
        checkFlags();
        canUserSeeFarmyFamily();

        $scope.verifyPhoneIfNeeded();

        trackEvent("setAlgoliaAuthenticatedUserToken", {authenticatedUserToken: $scope.currentUser?.id, type: "algolia"});

        $rootScope.$broadcast("user:loaded", {user: $scope.currentUser});
        $rootScope.$broadcast("user:updated", {user: $scope.currentUser});
        $rootScope.$broadcast("zipcode:changed");

        $timeout(() => {
          $scope.loadAccount();
        }, 2000);

        resolve(response.data);
      }, error => {
        // Force-unset authentication if the user could not be loaded
        $scope.isLoggedIn = false;
        $scope.currentUser = {};
        $scope.currentUserId = null;

        reject(error);
      });
    });
  };

  $scope.loadAccount = function() {
    return $q(function(resolve, reject) {
      $http.get("/api/frontend/user_accounts/my.json?exclude_transactions=t").then(function(response) {
        angular.extend($scope.account, response.data.account);
        resolve();
      });
    }).finally(function() {

    });
  };

  $scope.updateFilterPrefs = function(newPrefs) {
    if ($scope.currentUser && $scope.isLoggedIn) {
      $scope.currentUser.filter_prefs = newPrefs;
      return $http.post("/api/frontend/users/filter_prefs.json", {filter_prefs: newPrefs});
    } else {
      return $q((resolve, reject) => {
        $localStorage.filterPrefs = $localStorage.filterPrefs || {};
        angular.extend($localStorage.filterPrefs, newPrefs);

        resolve($localStorage.filterPrefs);
      });
    }
  };

  $scope.testCookies = function() {
    $http.post("/api/frontend/users/test_cookie.json").then(response => {

    });
  };

  $scope.updateCurrentSessionCookie = function(encodedSession) {
    if (location.host) {
      let cookieHost = location.host.split(":")[0];

      const hostnameTokens = location.host.split(":")[0].split(".");

      if (hostnameTokens.length > 1) {
        hostnameTokens.shift();
        cookieHost = "." + hostnameTokens.join(".");
      }

      $cookies.put(window.sessioncookiename, encodedSession, {expires: moment().add(window.sessioncookiettl, "seconds").toDate(), domain: cookieHost});
    } else {
      $cookies.put(window.sessioncookiename, encodedSession, {expires: moment().add(window.sessioncookiettl, "seconds").toDate()});
    }
  };

  /**
   * @desc Redirects user to the Login Page, while storing the URL
   * where the users needs to be redirected after login.
   * Also saves if there is need of a reload after login .
   *
   * @param object with two keys:
   *
   *  @field string : backToUrl => It's the url you want to send the user after login.
   *                     If it's empty, the user is gonna be redirected to the
   *                     page where the function was called from.
   *  @field boolean : forcePageReload => Forces a page reload when true
   *
   */
  $scope.showLoginScreen = function({backToUrl = "", forcePageReload = false} = {}) {
    if (window.UserLoginViewCtrl || window.UserLoginPageCtrl || window.location.pathname.includes("/login")) {
      console.log("Already at login screen");
      return;
    }
    const afterLoginUrl = backToUrl && backToUrl.length ? backToUrl : window.location.pathname + window.location.search;
    $scope.loginOptions = {backToUrl: encodeURIComponent(afterLoginUrl), forcePageReload};
    $location.url("/login");
  };

  $scope.showDeletedAccountModal = function() {
    const modal = $uibModal.open({
      animation: true,
      templateUrl: `/ng/templates/user_accounts/deleted_account_modal.html?locale=${I18n.locale}`,
      controller: "DeletedAccountModalCtrl",
      size: "lg",
      windowClass: "deleted-account-modal",
      resolve: {}
    });

    modal.result.then(() => {
      // Do something on submit? Not this time...
    });
  };

  $scope.changeLocale = function(locale) {
    I18n.locale = locale;
    window.locale = locale;
    $translate.use(locale);

    // Touch the user endpoint to update the locale:
    return $q((resolve, reject) => {
      $http.get(`/api/users/current.json?locale=${locale}&noaccount=t&nocards=t&noshipping=t&noorders=t`).then((r) => {
        resolve(r);
      });
    });
  };

  /**
   * This is related to 'select preferred order' modal behaviour. Each session
   * memorized the timestamp of the latest manual cart selection, to prevent
   * repetitive
   * @param date
   */
  $scope.setPreferredCartOrderSetAt = function(date) {
    $scope.preferredCartOrderSetAt = date;
    $localStorage.userSessionpreferredCartOrderSetAt = moment(date).toString();
  };

  /**
   * Get a seconds diff for the last time when the user manually selected
   * a cart from the multiple carts selection popup.
   *
   * @returns {number}
   */
  $scope.getSecondsSincePreferredCartOrderSet = function() {
    if ($scope.preferredCartOrderSetAt == null) {
      return 3600 * 365;
    } else {
      return ((new Date()) - $scope.preferredCartOrderSetAt) / 1000.0;
    }
  };

  $scope.resetPassword = function(email) {
    return $http.post("/api/password/recover.json", {spree_user: {email}});
  };

  $scope.loginWithFacebook = function() {

  };

  $scope.authenticateBySocialNetwork = function(strategy) {
    return $q((resolve, reject) => {
      $auth.authenticate(strategy).then((response) => {
        if (Rails.env == "development") console.log("Facebook ograph", response);

        if (response.error_fields || response.user_already_exists) {
          reject(response);
        } else if (response.need_to_sign_in) {
          $http.post("/authenticate_for_social_network.js", {spree_user: {email: response.data.user.email}}).then((response) => {
            $scope.currentUser = angular.extend($scope.currentUser || {}, response.data.user);
            window.currentZipcode = window.currentZipcode || $scope.currentUser.last_used_zipcode;
            window.currentUserId = $scope.currentUserId = response.data.user.id;
            window.currentUserHubId = response.data.user.hub_id;
            $scope.currentRoles.length = 0; // keep roles immutable
            _.each(response.data.roles, r => $scope.currentRoles.push(r));
            window.currentUserRoles = $scope.currentRoles;

            // Refresh authenticity token
            if (response.data.authenticity_token) { $("meta[name=csrf-token]").attr("content", response.data.authenticity_token) }

            checkRoles();
            checkFlags();
            canUserSeeFarmyFamily();
            UserNotifications.loadNotifications();

            $http.get("/users/my_cookies.html").then(r => {
              $scope.isLoggedIn = true;
              // This event will trigger a hub refresh by the Hubs service
              $rootScope.$broadcast("user:authenticated", {user: $scope.currentUser, hub_id: window.currentUserHubId});
              $rootScope.$broadcast("user:updated", {user: $scope.currentUser});
              $rootScope.$broadcast("zipcode:changed");
              resolve(response.data);
            });
          });
        } else {
          resolve(response);
        }
      });
    });
  };

  $scope.unlinkSocialNetwork = function(provider) {
    return $http.post("/api/users/unlink_social_network.json", {provider, id: window.currentUserId});
  };

  // Here's a collection of validators to use in address forms
  $scope.validators = {
    /**
     * ASYNCHRONOUS check for email uniqueness
     *
     * @param modelValue
     * @param viewValue
     * @returns {*}
     */
    emailTaken: function(modelValue, viewValue) {
      const value = modelValue || viewValue;

      return $q((resolve, reject) => {
        if (value == null || value.length <= 2) {
          resolve(true);
        } else {
          $http.get(`/check_email?email=${value}`).then(function(response) {
            if (response.data.exists) reject("taken");
            else resolve(true);
          }, error => {
            console.error(error);
            resolve(true);
          });
        }
      });
    },

    birthDateInvalid: function(modelValue, viewValue) {
      const value = modelValue || viewValue;
      const currentYear = moment().year();
      const year = parseInt(value);

      return $q((resolve, reject) => {
        if (value && value.length > 0 && isNaN(year)) {
          reject("invalid");
        } else if (!isNaN(year)) {
          if (currentYear - year < 16 || currentYear - year > 100) {
            reject("invalid");
          } else {
            resolve(true);
          }
        } else {
          resolve(true);
        }
      });
    },

    birthDate: function(modelValue, viewValue) {
      const value = modelValue || viewValue;

      return $q((resolve, reject) => {
        let date;
        const currentYear = moment().year();

        try {
          if (typeof(value) === "string") date = new Date(value);
          else if (typeof(value) === "object" && value._d) date = value.toDate(); // convert moment()
          else date = new Date(value);

          const year = date.getFullYear();

          if (isNaN(year)) reject({invalid: true});
          else if (currentYear - year > 105 || currentYear - year <= 14) reject({invalid: true});
          else resolve(true);
        } catch (e) {
          console.error(e);
          reject({invalid: true});
        }
      });
    }
  };

  $scope.verifyPhoneIfNeeded = function() {
    if ($scope.currentUser && $scope.currentUser.id && !$scope.phoneVerified && verifyPhoneModalInstance == null) { $scope.verifyPhone() }
  };
  /**
   * Triggers the phone verification popup
   */
  $scope.verifyPhone = function() {
    verifyPhoneModalInstance = $uibModal.open({
      animation: true,
      size: "md",
      keyboard: true,
      // backdrop: 'static',
      templateUrl: `/ng/templates/user_registration/verify_phone.html?locale=${$translate.use()}`,
      windowClass: "verify-phone-modal modal-rounded",
      controller: "VerifyPhonePopupCtrl",
      resolve: { }
    });

    verifyPhoneModalInstance.result.then(function(verificationResult) {
      if (verificationResult.success) {
        $scope.phoneVerified = true;
      }
    }).finally(() => {
      verifyPhoneModalInstance = null;
    });
  };

  // Private members

  const loginScreenModalInstance = null;
  var verifyPhoneModalInstance = null;

  /**
   * Assigns public flags corresponding to certain roles
   */
  function checkRoles() {
    $scope.isAdmin = _.find($scope.currentRoles, r => r == "admin" || r == "developer") != null;
    $scope.isCourier = $scope.currentRoles.some(r => r == "courier");
    $scope.isCorporateBuyer = _.find($scope.currentRoles, r => r == "corporate_buyer") != null;
    $scope.isSupplier = !!(_.find($scope.currentRoles, r => r == "supplier") != null && $scope.currentUser.supplier_id);
  }

  /**
   * Decodes additional data from the users/current.json reponse and sets appropriate scope flags
   */
  function checkFlags() {
    if ($scope.currentUser.pending_orders && $scope.currentUser.pending_orders.length > 0) {
      $scope.hasUnderliveredOrders = true;
    }
  }

  $scope.toggleFavorite = function(product) {
    if ($scope.isUpdating) return;
    $scope.isUpdating = true;
    const productId = product.id;

    return $q((resolve, reject) => {
      $http.post(`/api/frontend/favorites/${productId}/toggle.json`).then(function(response) {
        if (response?.data?.in_favorites) {
          $scope.currentUser.favorites = _($scope.currentUser?.favorites.concat(product)).uniq();
          trackEvent("add_to_wishlist", {products: product});
        } else {
          trackEvent("remove_from_wishlist", {products: product});
          $scope.currentUser.favorites = $scope.currentUser?.favorites.filter(favorite => favorite.id !== productId);
        }
        window.currentUserFavoritesIds = $scope.currentUser.favorites.map(favorite => favorite.id) || [];
        resolve(response?.data?.in_favorites);
        $rootScope.$broadcast("favorites:updated", $scope, productId);
        $rootScope.$broadcast("user:updated", {user: $scope.currentUser});
      }).finally(function() {
        $scope.isUpdating = false;
      });
    });
  };

  /**
   * @description Returns true if the user should be redirected to the landing page
   *              after login out. Depending on the current path.
   *              * @param path
   * @returns {boolean}
   */
  const shouldRedirectHome = () => {
    const urlsToRedirect = ["/account", "/orders", "invoices"];
    return urlsToRedirect.some(url => window.location.pathname.includes(url));
  };

  function canUserSeeFarmyFamily() {
    $scope.canSeeFarmyFamily = true;
  }

  $scope.resetLoginOptions = () => {
    $scope.loginOptions = {backToUrl: "", forcePageReload: false};
  };

  if ($scope.currentRoles && $scope.currentRoles.length > 0) checkRoles();
  if ($scope.currentUser && $scope.currentUser.id) checkFlags();

  // If the logged in flag is initially set,
  // it means the user was logged in since before load time
  // and we must load the user data
  if ($scope.isLoggedIn) {
    $scope.loadUser();
  }

  $rootScope.$on("user:balance:updated", $scope.loadAccount);
  $rootScope.$on("user:logout:outside", () => { $scope.logout() }); // react to logouts in another tab
  $rootScope.$on("smartpass:updated", () => {
    $scope.activeSmartpass = window.currentUserActiveSmartPass;
  });

  return $scope;
}]);
