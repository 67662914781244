import * as _ from "underscore";
import * as sprintf from "sprintf";

angular.module("app").controller("ShoppingListCtrl", ["$scope", "$http", "$element", "$location", "$window", "$timeout", "$templateCache", "$sce", "Alerts", "CartData", "StringToProductParser", "blockUI",
  function($scope, $http, $element, $location, $window, $timeout, $templateCache, $sce, Alerts, CartData, StringToProductParser, blockUI) {
    window.ShoppingListCtrl = $scope;

    $scope.CartData = CartData;
    $scope.inCartProductIds = [];
    $scope.listContainerWidth = 0;
    $scope.currentSelectedItemIndex = 0;
    $scope.clonedListToken = null;
    $scope.perPage = 6;

    const httpCacheKey = new Date().toISOString().slice(0, 10);
    const {apiUrl} = window;

    /// /////// SHOPPING LIST:

    $scope.getTokenFromUrl = function() {
      return (location.hashtag || "").replace("/t:", "");
    };

    $scope.shoppingList = {
      items: [],
      isParsed: false,
      token: $scope.getTokenFromUrl(),
      user_id: null
    };

    $scope.locale = I18n.locale;

    $scope.currentItem = null;
    $scope.processingList = $scope.allItemsProcessed = false;

    // TODO Mike: use this snippet to set textarea height upon new lines:
    // $(element).css('height', $(element).prop('scrollHeight').toString() + 'px')

    $scope.onInputPaste = function(event) {
      $timeout(function() {
        const lines = StringToProductParser.parseMultiple($scope.currentItem.input);
        if (lines.length > 0) {
          _(lines).each(function(line) {
            addItem(line);
          });
        }

        const removeIndex = $scope.currentItemIndex - lines.length;
        $scope.removeItem(removeIndex, true);
        removeEmpty();
        processAllItems();
      }, 10);
    };

    $scope.onFindClicked = function() {
      if (isListEmpty()) {
        return;
      }

      $scope.processingList = true;
      $window.scrollTo(0, 0);
      document.activeElement.blur();

      var allProcessedWatcher = $scope.$watch("allItemsProcessed", function() {
        if ($scope.allItemsProcessed) {
          allProcessedWatcher();

          if ($scope.shoppingList.token != "") {
            const clone_list = $scope.currentUserId != $scope.shoppingList.user_id;
            updateList($scope.shoppingList.token, clone_list);
          } else saveList();

          $scope.arrangeInCartItems();

          $scope.processingList = false;
          $scope.toggleMode();
        }
      });

      processAllItems();
      sendTrackingEvent("findProducts", _(_($scope.shoppingList.items).map(function(item) { return item.input })).reject(function(input) { return input == "" }).join(","));
    };

    $scope.toggleMode = function() {
      $scope.shoppingList.isParsed = !$scope.shoppingList.isParsed;

      if ($scope.shoppingList.isParsed) {
        _($scope.shoppingList.items).each(function(item, index) {
          $timeout(function() {
            item.closed = false;
            $window.scrollTo(0, 0);
            document.activeElement.blur();
            $scope.onItemLoaded(item);
          }, index * 100 + 100);
        });
        $scope.currentItemIndex = 0;
        $timeout(function() {
          document.activeElement.blur();
          $window.scrollTo(0, 0);
        }, 2000);
      } else {
        removeEmpty();
        resetItemHeights();
        $timeout(function() {
          document.activeElement.blur();
          $window.scrollTo(0, 0);
        }, 20);
      }
    };

    $scope.setCurrentItem = function(index) {
      $scope.currentItemIndex = index;
      onItemIndexChanged();
    };

    $scope.onItemBlurred = function(index) {
      const item = $scope.shoppingList.items[index];
      if (item.input.length > 0 && item.previousInput != item.input) {
        processItem(item);
        sendTrackingEvent("entryAdded", item.input);

        item.previousInput = item.input;

        if ($scope.shoppingList.isParsed) {
          updateList();
        }
      }
    };

    $scope.onSelectorClick = function(itemIndex, suggestionIndex) {
      if (itemIndex > -1) $scope.shoppingList.items[itemIndex].selectedIndex = suggestionIndex;
    };

    $scope.onItemLoaded = function(item) {
      recalculateHeight(item);
      updateTitleSizes();
    };

    $scope.onProductLoaded = function(item, product) {
      product.loaded = true;
      const loadedProducts = _(item.products).select(function(product) {
        return product.loaded;
      });
      if (loadedProducts.length == item.suggestions.products.length) {
        recalculateHeight(item);
      }
    };

    var resetItemHeights = function() {
      $timeout(function() {
        _($scope.shoppingList.items).each(function(item) {
          item.productWindowHeight = "0px";
        });
      }, 500);
    };

    var processAllItems = function() {
      removeEmpty();
      $scope.allItemsProcessed = areAllItemsProcessed();

      if (!$scope.allItemsProcessed) {
        var allProcessedWatcher = $scope.$watch("allItemsProcessed", function() {
          if ($scope.allItemsProcessed) {
            $scope.onInputKeyPressed();
            removeEmpty();
            allProcessedWatcher();
          }
        });

        _(_($scope.shoppingList.items).select(function(item) {
          return item.input.length > 0 && (!item.parsed || !item.suggestions || !item.suggestions.products || item.suggestions.products.length == 0);
        })).each(function(item) {
          processItem(item);
        });
      }

      $scope.removeUnits = false;
    };

    var areAllItemsProcessed = function() {
      return _(_($scope.shoppingList.items).reject(function(item) { return item.input.trim() == "" })).every(function(item) {
        return item.parsed && item.suggestions && item.suggestions.products;
      });
    };

    // Debugging
    $scope.areAllItemsProcessed = () => areAllItemsProcessed();

    var isListEmpty = function() {
      return _($scope.shoppingList.items).every(function(item) {
        return item.input.trim() == "";
      });
    };

    const numberOfEmptyItems = function() {
      return _($scope.shoppingList.items).select(function(item) {
        return item.input.trim() == "";
      }).length;
    };

    var removeEmpty = function() {
      if ($scope.shoppingList.items.length < 2) return;

      if (numberOfEmptyItems() > 1) {
        $scope.shoppingList.items = _($scope.shoppingList.items).reject(function(item) {
          return item.input.trim() == "";
        });
        addItem();
      }
    };

    /// /////// LIST ITEMS

    $scope.onInputKeyPressed = function(event) {
      if ($scope.currentItem.input.length > 0) {
        if ($scope.currentItem.previousInput != $scope.currentItem.input) $scope.currentItem.parsed = false;
        if ($scope.currentItem.input.length > 10) recalculateInputHeight($scope.currentItem);

        if (isLastItem()) {
          removeEmpty();
          addItem();
        }
      } else {
        if ($scope.currentItem.suggestions) resetItem($scope.currentItem);
        if ($scope.currentItem.previousInput && $scope.shoppingList.items.length > 1) removeCurrentOrLastIfEmpty();
      }

      if (event) {
        switch (event.keyCode) {
        case 13:
          onEnterPressed();
          event.preventDefault();
          break;
        case 8:
          onBackspacePressed();
          break;
        case 46:
          onDeletePressed();
          break;
        case 38:
          onUpArrowPressed();
          break;
        case 40:
          onDownArrowPressed();
          break;
        case 229:
          $timeout(function() {
            if ($scope.currentItem.input == "" && $scope.currentItem.previousInput) onBackspacePressed();
          }, 10);
          break;
        default:
          if ($scope.currentItem.input.trim() == "") $scope.currentItem.suggestions = null;
        }
      }
      $scope.currentItem.previousInput = $scope.currentItem.input;
    };

    $scope.onSearchIconClicked = function(item) {
      if (item.input.length > 0) {
        processItem(item);

        if ($scope.shoppingList.isParsed) {
          updateList();
        }
      }
    };

    var isLastItem = function(index) {
      index = index || $scope.currentItemIndex;
      return index == $scope.shoppingList.items.length - 1;
    };

    var onUpArrowPressed = function() {
      if ($scope.currentItemIndex == 0) {
        $scope.currentItemIndex = $scope.shoppingList.items.length - 1;
      } else {
        $scope.currentItemIndex -= 1;
      }
      onItemIndexChanged();
    };

    var onDownArrowPressed = function() {
      if ($scope.currentItemIndex == $scope.shoppingList.items.length - 1) {
        $scope.currentItemIndex = 0;
      } else {
        $scope.currentItemIndex += 1;
      }
      onItemIndexChanged();
    };

    var onDeletePressed = function() {
    };

    var onBackspacePressed = function() {
      if ($scope.currentItem.input.trim() == "") {
        if ($scope.currentItemIndex == 0 && $scope.shoppingList.items.length <= 1) {
          if ($scope.shoppingList.isParsed) $scope.toggleMode();
        } else {
          $scope.removeItem(null, true);
          $scope.onInputKeyPressed(null);
        }
      }
    };

    var removeCurrentOrLastIfEmpty = function() {
      if (isLastItem()) {
        $scope.removeItem(null, true);
      } else {
        const lastItem = _($scope.shoppingList.items).last();
        if (lastItem.input.length == 0) $scope.removeItem(-1);
      }
    };

    var onEnterPressed = function() {
      if ($scope.currentItem.input.length > 0) {
        if ($scope.currentItem.previousInput != $scope.currentItem.input) {
          $scope.currentItem.previousInput = $scope.currentItem.input;
          processItem($scope.currentItem, true);
          sendTrackingEvent("entryAdded", $scope.currentItem.input);
        }

        if ($scope.currentItemIndex == $scope.shoppingList.items.length - 1) {
          addItem("", true);
        } else {
          $scope.currentItemIndex += 1;
          onItemIndexChanged();
        }

        removeEmpty();

        if ($scope.shoppingList.isParsed) {
          updateList();
        }
      }
      return false;
    };

    var addItem = function(input, focus, placeholder) {
      input = input || "";
      focus = focus || false;
      placeholder = placeholder || null;

      // "Parsed" as in coming from the url "payload" parameter
      // specified here: https://farmyag.atlassian.net/browse/IM-4063
      const isObject = typeof input === "object";
      const isParsedInput = isObject && _(input).keys().indexOf("n") > -1;
      const hasAllRequiredKeys = isParsedInput && _(_(input).keys()).difference(["n", "q", "u"]).length == 0;

      if (isObject && !isParsedInput) return;

      const name = isParsedInput ? input.n : input;

      const item = {
        input: name,
        previousInput: name,
        output: {
          productName: "",
          quantities: [],
          searchKeyword: name
        },
        suggestions: null,
        parsed: false,
        selectedIndex: 0,
        showProduct: false,
        showAlternatives: false,
        more_pages_available: true,
        page_number: 1,
        productWindowHeight: "0px",
        placeholder,
        folded: true,
        closed: !$scope.shoppingList.isParsed
      };

      if (isParsedInput) {
        item.output.productName = name;

        if (input.q && parseFloat(input.q) > 0) {
          const quantityObj = {
            quantity: input.q,
            units: input.u.toLowerCase()
          };

          item.output.quantities.push(quantityObj);
          item.parsed = true;
          item.output.searchKeyword = getSearchKeywordFromOutput(item);
        }
      }

      $scope.shoppingList.items.push(item);
      $scope.allItemsProcessed = false;
      if (input.length > 10) recalculateInputHeight(item);
      if (focus) {
        $scope.currentItemIndex = $scope.shoppingList.items.indexOf(item);
        onItemIndexChanged();
      }
    };

    function getSearchKeywordFromOutput(item) {
      const measurement_units = ["l", "ml", "g", "mg", "kg"];
      const quantityObj = item.output.quantities ? item.output.quantities[0] : null;

      if (!quantityObj) return item.output.productName;

      const hasQuantity = quantityObj.quantity && parseFloat(quantityObj.quantity) > 0;
      const hasUnits = quantityObj.units && quantityObj.units.length > 0;
      const isMeasurement = hasQuantity && hasUnits && measurement_units.indexOf(quantityObj.units) > -1;

      let appendQuantity = `${hasQuantity ? quantityObj.quantity : ""}${!hasQuantity || !hasUnits || isMeasurement ? "" : " "}${hasQuantity && hasUnits ? quantityObj.units : ""}`;
      appendQuantity = appendQuantity.length > 0 ? " " + appendQuantity : "";

      return `${item.output.productName}${appendQuantity}`;
    }

    $scope.removeItem = function(index, focus) {
      index = (index && index >= -1) ? index : $scope.currentItemIndex;
      focus = focus || false;

      $scope.shoppingList.items.splice(index, 1);
      if (focus) {
        if ($scope.currentItemIndex > 0) {
          $scope.currentItemIndex -= 1;
        }

        onItemIndexChanged();
      }
    };

    $scope.loadMore = function(item) {
      item.page_number = item.page_number || 1;
      parseInput(item);
      showMore(item);
    };

    var processItem = function(item, forceParse) {
      forceParse = forceParse || null;
      item.closed = true;
      item.page_number = 1;
      item.productWindowHeight = "0px";
      item.placeholder = null;

      if (!item.parsed || forceParse) parseInput(item);
      searchProducts(item);
    };

    var resetItem = function(item) {
      item = {
        input: "",
        output: {
          productName: "",
          quantity: 0,
          units: ""
        },
        suggestions: null,
        parsed: false,
        selectedIndex: 0,
        showProduct: false,
        showAlternatives: false,
        productWindowHeight: "0px",
        more_pages_available: true,
        page_number: 1,
        folded: true,
        closed: !$scope.shoppingList.isParsed,
        previousInput: null
      };
    };

    var parseInput = function(item) {
      item = item || $scope.currentItem;
      item.output = StringToProductParser.parseString(item.input);

      item.output.searchKeyword = getSearchKeywordFromOutput(item);
    };

    var updateTitleSizes = function() {
      _($scope.shoppingList.items).each(function(item) {
        if (item.suggestions) {
          _(item.suggestions.products).each(function(product) {
            updateTitleSize(product);
          });
        }
      });
    };

    function updateTitleSize(product) {
      if (product.name.length > 50) {
        product.titleFontSize = "16px";
      } else if (product.name.length > 30) {
        product.titleFontSize = "18px";
      } else if (product.name.length > 22) {
        product.titleFontSize = "20px";
      } else product.titleFontSize = "24px";
    }

    // Decides whether to just show hidden suggestions or to load a new page from the API server.
    var showMore = function(item) {
      item.page_number++;
      searchProducts(item, true);
    };

    // Note: sprintf
    const searchProducts = function(item, append) {
      append = append || false;
      const keywords = $scope.removeUnits ? item.output.productName : item.output.searchKeyword;

      const urlComponents = {
        cachekey: httpCacheKey,
        keywords,
        per_page: 9,
        locale: I18n.locale,
        presentation: "catalog",
        page: item.page_number,
        exclude_filters: "t"
      };

      const url = "/api/products.json";

      item.isLoading = true;

      // Extract response handling from the callback to avoid repetition and
      // also to call asynchronously from different places.
      function handleResponse(response) {
        if (append && item.suggestions.products) {
          item.suggestions.products = item.suggestions.products.concat(response.data.products);
        } else {
          item.suggestions = {};
          item.suggestions.products = response.data.products;
          item.product_ids = _(item.suggestions.products).map(function(product) {
            return product.id;
          });
        }

        item.isLoading = false;

        if (!_.any($scope.taxon_suggestions)) {
          item.suggestions.taxons = response.data?.taxons?.slice(0, 3);
        }

        item.more_pages_available = parseInt(response.data?.total_pages) > parseInt(response.data?.current_page);

        // This experimental feature is still unused. Would send to cart the first found product.
        if ($scope.autoFillCart && item.suggestions.products[0]) {
          const productToCart = item.suggestions.products[0];
          const variantToCart = productToCart.variants[0];

          CartData.setCartVariant(productToCart.id, variantToCart);
        }
      }

      $http.get(url, {cache: true, params: urlComponents}).then(function(response) {
        item.input = keywords;
        handleResponse(response);
      }).finally(function() {
        blockUI.stop();
        $scope.arrangeInCartItems();
        $scope.onSelectorClick($scope.shoppingList.items.indexOf(item), 0);
        item.parsed = true;
        item.closed = !$scope.shoppingList.isParsed;
        recalculateHeight(item);

        $timeout(() => {
          $scope.allItemsProcessed = areAllItemsProcessed();
        }, 100);

        $scope.onItemLoaded(item);
      });
    };

    // Will match item.output.productName against result search_keywords or names.
    // +true+ only if the relation num_of_hits/num_of_products is above 80%.
    const checkSearchConsistency = function(item, products) {
      let keywordHits, productKeywords;

      // Collect "internal_search_keywords".
      productKeywords = _(_(products).map(p => p.internal_search_keywords ? p.internal_search_keywords.toLowerCase() : null)).compact();

      if (productKeywords.length < products.length) {
        productKeywords = productKeywords.concat(_(_(products).map(p => p.name.toLowerCase())).compact());
      }

      keywordHits = _(productKeywords).select(k => k.indexOf(item.output.productName.toLowerCase()) > -1);

      if (productKeywords.length == 0) return false;
      return keywordHits.length / productKeywords.length > 0.80;
    };

    const loadList = function() {
      const url = apiUrl("/shopping_lists/" + $scope.shoppingList.token + ".json");
      $http.get(url).then(function(response) {
        $scope.shoppingList = response.data;
        loadAllItems();
        $scope.setCurrentItem($scope.shoppingList.items.length - 1);
        sendTrackingEvent("loaded");
      });
    };

    var saveList = function() {
      const url = apiUrl("/shopping_lists.json");
      const shopping_list = getShoppingListParams();
      $http.post(url, {shopping_list}).then(function(response) {
        if (response.data) {
          $scope.shoppingList.token = response.data.token;
          $scope.shoppingList.id = response.data.id;
          if ($scope.shoppingList.token != "") {
            $location.changeUrl(sprintf("/shopping_lists/%s", $scope.shoppingList.token));
          }
        }
      });
    };

    var updateList = function(token, clone_list) {
      token = token || $scope.shoppingList.token;
      clone_list = clone_list || false;
      const url = apiUrl("/shopping_lists/" + token + ".json");
      const shopping_list = getShoppingListParams();
      $http.patch(url, {shopping_list}).then(function(response) {
        if (response.data) {
          if (clone_list) {
            if ($scope.clonedListToken) {
              updateList($scope.clonedListToken);
            } else clone_for_current_user();
          }
        // do nothing?
        }
      });
    };

    var clone_for_current_user = function() {
      const url = apiUrl("/shopping_lists/" + $scope.shoppingList.token + "/clone_for_current_user.json");
      $http.post(url).then(function(response) {
        $location.changeUrl(sprintf("/shopping_lists/%s", response.data.token));
      });
    };

    var loadAllItems = function() {
      var allProcessedWatcher = $scope.$watch("allItemsProcessed", function() {
        if ($scope.allItemsProcessed) {
          $scope.onInputKeyPressed();
          allProcessedWatcher();
        }
      });

      _($scope.shoppingList.items).each(function(item) {
        item.previousInput = item.input;
        processItem(item);
      });
    };

    $scope.arrangeInCartItems = function() {
      getInCartProductIds();
      getInCartVariantIds();
      if ($scope.allItemsProcessed && $scope.inCartProductIds != []) {
        _($scope.shoppingList.items).each(function(item) {
          if (item.suggestions) {
            _(item.suggestions.products).each(function(product) {
              product.inCart = $scope.inCartProductIds.indexOf(product.id) > -1;

              if (product.inCart) {
                const incartVariantId = _($scope.CartData.cart.line_items).find(function(line) { return line.product_id == product.id }).variant.id;
                product.variantIndex = product.variants.indexOf(_(product.variants).find(function(variant) { return variant.id == incartVariantId }));
                product.variantIndex = product.variantIndex > -1 ? product.variantIndex : null;
                item.selectedProduct = item.suggestions.products[item.selectedIndex];
              }
            });
          }
        });
      }

      $timeout(() => {
        $scope.recalculateHeightAll();
      }, 100);
    };

    $scope.recalculateHeightAll = function() {
      _($scope.shoppingList.items).each(item => {
        recalculateHeight(item);
      });
    };

    var getInCartProductIds = function() {
      $scope.inCartProductIds = _($scope.CartData.cart.line_items).map(function(line) {
        return line.product_id;
      });
    };

    var getInCartVariantIds = function() {
      $scope.inCartVariantIds = _($scope.CartData.cart.line_items).map(function(line) {
        return line.variant.id;
      });
    };

    var getShoppingListParams = function() {
      return {
        id: $scope.shoppingList.id,
        token: $scope.shoppingList.token,
        user_id: $scope.shoppingList.user_id,
        items: _($scope.shoppingList.items).map(function(item) {
          if (item.input.length > 0) {
            return {
              input: item.input,
              product_ids: item.product_ids
            };
          }
        })
      };
    };

    var onItemIndexChanged = function() {
      $scope.currentItem = $scope.shoppingList.items[$scope.currentItemIndex];

      $timeout(function() {
        $("#list-item-" + $scope.currentItemIndex + " textarea")[0].focus();
      }, 10);
    };

    var recalculateHeight = function(item) {
      const index = $scope.shoppingList.items.indexOf(item);
      const correction = (!item.productWindowHeight || item.productWindowHeight == "0px") ? 15 : 0;

      $timeout(function() {
        item.productWindowHeight = ($("#list-item-" + index + " .parsed-block")[0].scrollHeight + correction).toString() + "px";
        updateTitleSizes();
      }, 500);
    };

    var sendTrackingEvent = function(action, label) {
      window.Tracking.sendShoppingListEvent(action, label);
    };

    var recalculateInputHeight = function(item) {
      const index = $scope.shoppingList.items.indexOf(item);
      const element = $("#list-item-" + index + " textarea");

      if (element[0]) {
        element.css("height", "auto");

        const height = (element.prop("scrollHeight") + 3).toString() + "px";
        element.css("height", height);
      }
    };

    if ($scope.shoppingList.token) {
      loadList();
    } else {
      var initialTokenWatcher = $scope.$watch("initialToken", function(newVal) {
        if (newVal != "") {
          $scope.shoppingList.token = newVal;
          loadList();
        } else sendTrackingEvent("created");

        initialTokenWatcher();
      });
    }

    var userIdWatcher = $scope.$watch("userId", function(newVal) {
      if (newVal != "") {
        $scope.shoppingList.user_id = newVal;
        userIdWatcher();
      }
    });

    const payloadWatcher = $scope.$watch("payload", (newVal) => {
      if (newVal) {
        if (newVal.length > 0) {
        // This flag enables the feature => would add the first result product to cart automatically.
        // Not enabled yet because it might provide a confusing experience specially for first-time customers.
          $scope.autoFillCart = false;

          $scope.removeUnits = true;

          const items = JSON.parse(newVal);
          _(items).each((item) => {
            addItem(item);
          });

          processAllItems();
        }

        payloadWatcher();
      }
    });

    $scope.$watch("CartData.cart.updated_at", function(updatedAt) {
      if (updatedAt == null) { return }

      $scope.arrangeInCartItems();
    });

    $scope.$watch("shoppingList.items", function(items) {
      if (items && items.length > 0) updateTitleSizes();
    });

    if ($scope.shoppingList.items.length == 0) {
      $timeout(() => {
        addItem("", true, $translate.instant("shopping_lists.show.search_placeholder_1"));
        addItem("", false, $translate.instant("shopping_lists.show.search_placeholder_2"));
        addItem("", false, $translate.instant("shopping_lists.show.search_placeholder_3"));
      }, 100);
    }

    $scope.$on("$destroy", function() {
      window.ShoppingListCtrl = null;
    });

    // Debounce showing the interface by a little
    $timeout(() => {
      $scope.ctrlInitialized = true;
    }, 50);
  }]);
