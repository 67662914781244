import {useEffect, useState} from "react";
import {useSelector, useCartActions} from "../../shared-services/redux-tiny";
import globalState from "../../shared-services/globalState";
import useTracking from "../../react-services/useTracking";
import useFullLoader from "../loaders/useFullLoader";
import useModalQueue from "../topLayer/modals/useModalQueue";
import {useLocation} from "react-router-dom";
import FrontendSettings from "../../frontendSettings";
import AngularIntegration from "../../angular-integration";

const useCart = () => {
  const {setCurrentCart, requestCartLoad} = useCartActions();
  const {trackEvent} = useTracking();
  const {getCurrentCart, lineItemFromProduct, quantityIndex, variantByIndex, buildLineItem, signalCartSave} = cartTools();
  const {launchLoader} = useFullLoader();
  const location = useLocation();
  const {currentZipcode} = useSelector(state => state.session);

  const {pushToQueue} = useModalQueue("zipcode");
  const openZipcodeModal = (product, variant, options) => {
    pushToQueue("zipcode", {
      onClose: () => {
        const {getGlobalState} = globalState();
        const {currentZipcode} = getGlobalState("session");
        
        if (currentZipcode && product) setCartVariant(product, variant, {skipZipcode: true});
      }
    });
  };

  const [triggerUnavailableBehaviour, setTriggerUnavailableBehaviour] = useState(false);
  const [showUnavailableModal, setShowUnavailableModal] = useState(false);

  const setCartVariant = (product, variant, options = {}) => {
    // If zipcode has not yet been defined.
    if (!currentZipcode && !options.skipZipcode) {
      openZipcodeModal(product, variant, options);
      return;
    }

    const currentCart = getCurrentCart();
    if (!currentCart?.id) return;

    const newLineItems = currentCart.line_items.filter(item => item.product_id !== product.id);
    const newLineItem = buildLineItem(product, variant);

    const updatedCart = {
      ...currentCart,
      line_items: [
        ...newLineItems,
        newLineItem
      ]
    };

    // For tracking purposes
    const oldVariant = options?.oldVariant || {quantity_index: 0, price: 0, quantity_in_units: 0};
    const newVariant = variant?.variantObject ? {variant, ...variant.variantObject} : variant;
    const trackingQuantity = (newVariant?.quantity_in_units || 0) - oldVariant?.quantity_in_units;
    const trackingValue = Math.abs(parseFloat(((newVariant?.price || 0) - (oldVariant?.price || 0)).toFixed(2)));

    trackEvent(
      trackingQuantity < 0 ? "removefromcart" : "addtocart",
      {
        gtmObject: {value: trackingValue},
        products: {...newLineItem, trackingQuantity: Math.abs(trackingQuantity)},
        callerLocation: options?.callerLocation
      }
    );

    setCurrentCart(updatedCart);

    signalCartSave();
    return newLineItem;
  };

  // Public members
  const loadCart = () => {
    requestCartLoad();
  };

  const removeFromCart = (product, options) => {
    const currentCart = getCurrentCart();
    const lineItem = lineItemFromProduct(product, currentCart);
    if (!lineItem) return;
    if (location.pathname.includes("/cart")) {
      launchLoader({duration: FrontendSettings?.loaderDefaultDuration});
    }
    const lineItems = currentCart.line_items.filter(item => item.product_id !== product.id);

    const updatedCart = {
      ...currentCart,
      line_items: [...lineItems]
    };
    const trackingValue = lineItem?.variant?.price || lineItem?.unit_price;
    trackEvent(
      "removefromcart",
      {
        gtmObject: {value: Math.abs(parseFloat(trackingValue))},
        products: {...lineItem, trackingQuantity: Math.abs(lineItems?.variant?.quantity_in_units || 1)},
        callerLocation: options?.callerLocation
      }
    ).finally(() => {
      trackEvent(
        "product_removed_from_cart",
        {
          gtmObject: {value: Math.abs(parseFloat(trackingValue))},
          products: {...lineItem, trackingQuantity: Math.abs(lineItems?.variant?.quantity_in_units || 1)},
          callerLocation: options?.callerLocation
        }
      );
    });
    setCurrentCart(updatedCart);
    signalCartSave();
  };

  const addToCart = (product, options) => {
    if (quantityIndex(product)) increaseQuantity(product, {callerLocation: options?.callerLocation});
    else return setCartVariant(product, product?.variants[0], {callerLocation: options?.callerLocation});
  };

  const increaseQuantity = (product, options) => {
    const currentIndex = quantityIndex(product);
    // if (product.id === 29774) debugger;
    if (currentIndex === null) {
      addToCart(product, {callerLocation: options?.callerLocation});
    }

    const variant = variantByIndex(product, currentIndex + 1);
    const oldVariant = variantByIndex(product, currentIndex);
    if (variant) {
      return setCartVariant(product, variant, {oldVariant, callerLocation: options?.callerLocation});
    }
  };

  const decreaseQuantity = (product, options) => {
    const currentIndex = quantityIndex(product);
    if (!currentIndex) {
      removeFromCart(product, {callerLocation: options?.callerLocation});
      return;
    }

    const variant = variantByIndex(product, currentIndex - 1);
    const oldVariant = variantByIndex(product, currentIndex);

    if (variant) {
      return setCartVariant(product, variant, {oldVariant, callerLocation: options?.callerLocation});
    } else removeFromCart(product, {callerLocation: options?.callerLocation});
  };

  const emptyCart = (loader = true, options) => {
    if (loader) launchLoader({duration: FrontendSettings?.loaderDefaultDuration});
    const currentCart = getCurrentCart();
    trackEvent("removefromcart", {gtmObject: {value: currentCart?.item_total}, products: currentCart?.line_items, callerLocation: options?.callerLocation})
      .finally(() => {
        trackEvent("empty_cart", {gtmObject: {value: currentCart?.item_total}, products: currentCart?.line_items, callerLocation: options?.callerLocation});
      });
    setCurrentCart({...currentCart, line_items: []});
    signalCartSave();
  };

  useEffect(() => {
    if (triggerUnavailableBehaviour) {
      setTriggerUnavailableBehaviour(false);
      setShowUnavailableModal(true);
    }
  }, [triggerUnavailableBehaviour]);

  return {
    addToCart,
    removeFromCart,
    increaseQuantity,
    decreaseQuantity,
    emptyCart,
    loadCart
  };
};

export default useCart;

export const cartTools = () => {
  const {getGlobalState, setGlobalState} = globalState();

  const getCurrentCart = () => {
    return getGlobalState("cart")?.currentCart;
  };

  const lineItemFromProduct = (product) => {
    const cart = getCurrentCart();
    if (!cart?.line_items) return;

    return cart.line_items.find(lineItem => lineItem.product_id === product.id);
  };

  const quantityIndex = (product) => {
    const lineItem = lineItemFromProduct(product);
    const variant = product.variants.find(variant => variant.id === lineItem?.variant_id);

    return variant ? product.variants.indexOf(variant) : null;
  };

  const variantByIndex = (product, index) => {
    if (!product?.variants) return;
    return product?.variants[index];
  };

  const variantById = (product, variantId) => {
    if (!product?.variants) return;
    return product?.variants.find(variant => variant.id === variantId);
  };

  const buildLineItem = (product, variant, options = {}) => {
    const {referrer} = options;

    const {weight_control_billing: wcBilling, weight_control_deviation: wcDev} = product;

    return {
      product_id: product.id,
      quantity: 1,
      price: variant.price,
      total: variant.price,
      variant,
      variant_id: variant.id,
      referrer,
      ax_primary_taxon: product?.ax_primary_taxon,
      quantity_in_units: variant.quantity_in_units,
      weight_control_billing: wcBilling,
      weight_control_surcharge: wcBilling ? product.price * wcDev : 0,
      productData: product
    };
  };

  // Temporary, so that we can use this tool from Angular.
  const emptyCart = () => {
    const {$broadcast} = AngularIntegration;
    const currentCart = getCurrentCart();
    const newCart = {...currentCart, line_items: []};

    setGlobalState("cart", {currentCart: newCart});
    signalCartSave();

    $broadcast("react:emptyCart");
  };

  const signalCartSave = () => {
    window.dispatchEvent(new CustomEvent("globalCartTimer"));
  };

  return {getCurrentCart, emptyCart, lineItemFromProduct, quantityIndex, variantByIndex, variantById, buildLineItem, signalCartSave};
};
