const frontendSettings = {
  farmyFamily: {
    forAdminsOnly: false
  },
  defaultAlgoliaIndexName: "farmy_production.single",
  loaderDefaultDuration: 1000,
  certificatesBlock1: ["best_price"] // List of certificates to display on search dropdown and top of image in pdp
};

export default frontendSettings;
